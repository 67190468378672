import React, { lazy } from "react";

import { Routes, Route, useLocation } from "react-router-dom";
// import EventElements from "./components/Events/Data";
// Routes
const Home = lazy(() => import("./pages/Home/Home"));
const News = lazy(() => import("./pages/News/News"));

const Team = lazy(() => import("./pages/Team/Team"));
const Contact = lazy(() => import("./pages/ContactUs/Contact"));
const About = lazy(() => import("./pages/About/About"));
const LightKonnect = lazy(() => import("./components/Products/LightKonnect"));
const LightKonnectFiber = lazy(() =>
  import("./components/Products/LightKonnectFiber")
);
const LightSiP = lazy(() => import("./components/Products/LightSiP"));
const Error = lazy(() => import("./components/404/Error404"));
const Investors = lazy(() => import("./pages/Investors/Investors"));
const Gallery = lazy(() => import("./pages/Gallery/Gallery"));
const Memberships = lazy(() => import("./pages/Memberships/Memberships"));
const Technology = lazy(() => import("./pages/Technology/Technology"));
const WhitePapers = lazy(() => import("./pages/Technology/whitePapers"));
const Terms = lazy(() => import("./components/Others/Terms"));
const Privacy = lazy(() => import("./components/Others/Privacy"));
const Blogs = lazy(() => import("./pages/Blog/Blogs"));
const Events = lazy(() => import("./pages/Events/Events.jsx"));
const EventPost = lazy(() => import("./components/Events/EventPost"));
const OptoElectronicsSystemIntegrationForDataCenterAndCloudlets = lazy(() => import("./components/Events/OptoElectronicsSystemIntegrationForDataCenterAndCloudlets"));
const SemiConWest2023 = lazy(() => import("./components/Events/SemiConWest2023"));

const Publications = lazy(() => import("./pages/Publications/Publications"));
const CollaborationsPartners = lazy(() =>
  import("./components/Collaborations&Partners/Collaborations")
);
const Careers = lazy(() => import("./pages/Careers/Careers"));

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Home />} />

      {/* Company */}
      <Route path="/company/about" element={<About />} />
      <Route path="/company/team" element={<Team />} />
      <Route path="/company/investors" element={<Investors />} />
      <Route path="/company/gallery" element={<Gallery />} />

      <Route
        path="/company/collaborations&partners"
        element={<CollaborationsPartners />}
      />
      <Route path="/company/memberships" element={<Memberships />} />


      {/* Products */}
      <Route path="/products/lightkonnect" element={<LightKonnect />} />
      <Route
        path="/products/lightkonnectFiber"
        element={<LightKonnectFiber />}
      />
      <Route path="/products/lightsip" element={<LightSiP />} />

      {/* Insights */}
      <Route path="/Insights/Blog" element={<Blogs />} />
      <Route path="/Insights/Events" element={<Events />} />

      <Route path="/Insights/Publications" element={<Publications />} />
      <Route path="/Insights/news" element={<News />} />
      <Route path="/Insights/technology" element={<Technology />} />

      {/* Contact Info */}
      <Route path="/contact" element={<Contact />} />
      <Route path="/careers" element={<Careers />} />

      {/* T&C, Privacy */}
      <Route path="/terms&conditions" element={<Terms />} />
      <Route path="/privacypolicy" element={<Privacy />} />

      {/* 404 */}
      <Route path="*" element={<Error />} />

      {/* Blog & white Paper Url: */}
      <Route path="/insights/Technology/:link" element={<WhitePapers />} />

      {/* Events */}
      <Route path="/Insights/Events/:link" element={<EventPost />} />
      <Route path="/Insights/UpComingEvents/OptoElectronicsSystemIntegrationForDataCenterAndCloudlets" element={<OptoElectronicsSystemIntegrationForDataCenterAndCloudlets />} />
      <Route path="/Insights/UpComingEvents/SemiConWest2023" element={<SemiConWest2023 />} />
      


      {/* open project */}

    </Routes>
  );
}

export default AnimatedRoutes;
