import "./App.css";
// React Router Dom
import { BrowserRouter as Router } from "react-router-dom";

import React, { lazy, Suspense, useState, useEffect } from "react";
// import Footer from "./components/Footer/Footer";
import SemiConLogo from "./SemiConWestLogo.png";

import PageRoutes from "./PageRoutes";
import BounceLoader from "react-spinners/BounceLoader";
import ScrollToTop from "react-scroll-up-update";
import { NavLink } from "react-router-dom";

import { ImCross } from "react-icons/im";

const NavBar = lazy(() => import("./components/NavBar/NavBar"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const ConferenceLink = "/Insights/UpComingEvents/SemiConWest2023";

function Loading() {
  return (
    <div className=" flex items-center justify-center justify-items-center w-screen h-screen">
      <BounceLoader />
    </div>
  );
}

function App() {
  const GoToTop = function () {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // BANNER and Link
  const [showImage, setShowImage] = useState(true);
  const [displayLink, setDisplayLink] = useState("hidden");
  const [displaySmallBanner, setDisplaySmallBanner] = useState("");

  // Help for seminar registration
  const [checkHelp, setCheckHelp] = useState(false);
  const [helpText, setHelpText] = useState(" Help ?");

  useEffect(() => {
    if (checkHelp) {
      setHelpText(<p> &lt; Go Back</p>);
    } else {
      setHelpText(<p> Help ?</p>);
    }
  }, [checkHelp]);

  return (
    <div className="font-Century-Gothic  ">
      <Router>
        <NavBar />
        <ScrollToTop showUnder={160}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-12 h-12  hover:text-red-900 hover:border-2 hover:border-red-900 bg-white hover:opacity-100 opacity-50  p-2  m-8 text-red-900 rounded-full drop-shadow-xl"
            >
              <path d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
            </svg>
          </span>
        </ScrollToTop>
        <Suspense fallback={<Loading />}>
          <PageRoutes />
        </Suspense>

        <Footer />

        {/* <div
          className={`flex flex-row items-center md:justify-between just md:mr-20 justify-end ${displaySmallBanner} sticky  bottom-0 `}
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-delay="100"
          data-aos-once
        >
          <div className="md:flex hidden"></div>
          <div className="flex relative">
            <span className="bg-white absolute  z-[100] right-[0px]   p-2 text-[10px]  text-black">
              <ImCross onClick={() => setDisplaySmallBanner("hidden")} />
            </span>
            <NavLink
              to={ConferenceLink}
              onClick={GoToTop}
              className="flex  group hover:py-4 flex-col gap-2 py-2 bg-backdrop-opacity-80 drop-shadow-md transition-all duration-300  bg-[#FFA200] text-white font-semibold hover:text-[#FFA200] hover:bg-white  backdrop-blur-sm  px-4 semicon-button  "
            >
              <span className="flex gap-2">Join us at conference</span>
              <p className="flex items-center gap-2">
                <img
                  src={SemiConLogo}
                  alt="OFC"
                  className="md:w-36 w-36 h-max"
                />
                2023{" "}
              </p>
              <p className="hidden group-hover:block group-hover:bg-[#FFA200] group-hover:text-white bg-white text-center text-[#FFA200] transition-all duration-500">
                {" "}
                Learn More{" "}
              </p>
            </NavLink>
          </div>
        </div> */}
      </Router>
    </div>
  );
}

export default App;
